/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';
import scrollDir from 'scrolldir';
import autosize from 'autosize';
import {TweenMax}  from "gsap";
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'gsap/src/minified/plugins/ScrollToPlugin.min.js';

/* Imports ---------------------------------------------------------*/

import './templates';
import './video';

/* Initial -------------------------------------------------------*/

scrollDir();

/* Variables -------------------------------------------------------*/

let $body = $('body');
let controller = new ScrollMagic.Controller();

let currentURL = location.protocol+'//'+location.host+location.pathname;

// ==========================================//
// Parallax
// ==========================================//

(function ( $ ) {

	$.fn.inView = function() {
		if ($(window).scrollTop() + $(window).height() >= $(this).offset().top && $(window).scrollTop() <= $(this).offset().top + $(this).height()) {
			return true;
		}
		return false;
	};

	$.fn.yPercent = function() {
		return ($(this).offset().top - $(window).scrollTop()) / $(window).height() * 100;
	};

	$.fn.lazyLoad = function() {

		var $parent = $(this);
		var $image = $parent.find('[lazy]');


		// function ()


		$parent.renderImg = function(){

			$image.each(function(){
				// Find the src
				var src = $(this).attr('lazy');
				var img;
				var $all;

				// Make sure src is defined
				if (src != undefined) {
					// Find all with matching src
					$all = $(`[lazy="${src}"]`);
					// Render the image
					img = new Image();
					img.src = src;

					img.onload = function () {
						// Apply image src to all that match
						$all.each(function(){
							if ($(this).is('img')) {
								$(this).attr('src', src);
							}else {
								$(this).css({
									'background-image': `url('${src}')`,
								})
							}
							// fadeIn($(this)[0]);
						})
						$all.removeAttr('lazy');
					}
				}
			})
		}


		$parent.init = function(){
			if ($parent.inView()) {
				$parent.renderImg();
			}else {
				$(window).on('scroll', _.throttle(function(){
					if ($parent.find('[lazy]').length) {
						if ($parent.inView()) {
							$parent.renderImg();
						}
					}
				}, 400))
			}
		}


		$parent.init();	
	}

	$.fn.parallax = function( options ) {

		var $this = $(this);
		var settings = $.extend({
			scale: 0.2,
			duration: 0.4,
			buffer: 100
		}, options );
		var transition = `transform ${settings.duration}s ease-out`;

		$this.css({
			'-webkit-transition'    : 	transition,
			'-moz-transition'	    : 	transition,
			'-o-transition'		    : 	transition,
			'-ms-transition'	    : 	transition,
			'transition'		    : 	transition,
		});

		$(window).on('scroll', _.throttle(function(){

			if ($this.inView()) {
				$this.css({
					'transform': `translateY(${$this.yPercent() * -settings.scale}%)`
				})
			}

		}, settings.buffer))

	};

	$.fn.drawSVG = function(options) {
		let settings = {
			start: 0,
			finish: 0,
			speed: 1,
			direction: 'forward',
			hook: 0.9,
			reverse: false, 
			duration: 0
		}

		this.construct = function(options){
			$.extend(settings , options);
		}

		this.construct(options);

		let $this = $(this);
		let $path = $this.find('svg :not(g):not(title):not(desc)');

		// console.log($path);

		$path.each(function(){
			let $thisPath = $(this);
			let lineLength = $thisPath[0].getTotalLength();
			let draw = new TimelineMax();
			let from;
			let to;

			$thisPath.css({
				'stroke-dasharray': lineLength,
				'stroke-dashoffset': lineLength
			});

			switch(settings.direction) {
				case 'reverse':
				from = lineLength;
				to = lineLength*2;
				break;
				default:
				from = lineLength;
				to = 0;
			}

			draw.fromTo($thisPath, settings.speed, {strokeDashoffset: from}, {strokeDashoffset: to, ease: Power2.easeInOut});

			let drawScene = new ScrollMagic.Scene({triggerElement: $this[0], triggerHook: settings.hook, reverse: settings.reverse, duration: settings.duration})
			.setTween(draw)
			.addTo(controller);
		})

		// $.fn.hoverDrawSVG = function() {

		// 	let $this = $(this);
		// 	let $parent = $this.find('.js-hover-drawSVG-watch');
		// 	let $path = $parent.find('> svg :not(g):not(title):not(desc)');

		// 	$path.each(function(){
		// 		let $thisPath = $(this);
		// 		let lineLength = $thisPath[0].getTotalLength();

		// 		$thisPath.css({
		// 			'stroke-dasharray': lineLength + 10,
		// 			'stroke-dashoffset': lineLength + 10
		// 		});

		// 		setTimeout(function(){
		// 			$parent.css({
		// 				'visibility': 'visible'
		// 			})
		// 		}, 1000)

		// 		$this.on({
		// 			mouseenter: function(){
		// 				$thisPath.css({
		// 					'stroke-dashoffset': 0
		// 				});
		// 			},
		// 			mouseleave: function(){
		// 				$thisPath.css({
		// 					'stroke-dashoffset': lineLength + 10
		// 				});
		// 			}
		// 		})
		// 	})
		// }




	};

	function matchHeight($target) {
	    // Initialise at 0
	    var currentHeight = 0;

	    // All targets to show real height
	    $target.css('height', 'auto').addClass('clearfix');

	    // Test each target height against initial height
	    for (var i = 0; i < $target.length; i++) {
	    	if ($target.eq(i).height() >= currentHeight) {
	    		currentHeight = $target.eq(i).height();
	    	}
	    }

	    // Set all targets to have equal height
	    // console.log(currentHeight);
	    $target.height(currentHeight);
	    $target.attr({
	    	'data-height': currentHeight,
	    });
	}

	$.fn.equalize = function( options ) {

		var $this = $(this);
		var $target;

	    // Control over how often this even is fired
	    var settings = $.extend({
	    	buffer: 500
	    }, options );

	    // when == 1 event will fire on scroll;
	    var widthChanged = 1;


	    // Find target
	    if ($this.data('equalize') != undefined) {
	    	$target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
	    }else {
	    	$target = $this.find(`[data-equalize-watch]`);
	    }

	    // console.log('targets: ',$target.length);
	    matchHeight($target);

	    // On scroll event
	    $(window).on('scroll', _.throttle(function(){

	      // If this container is visible and the content has changed width
	      if (widthChanged && $this.inView()) {
	      	// console.log('match');

	        // Tell function width has no longer changed
	        widthChanged = 0;

	        // Match target heights
	        matchHeight($target);
	    }
	}, settings.buffer));

	    // On resize event
	    $(window).on('resize', _.debounce(function(){

	       // Tell function width has changed
	       widthChanged = 1;

	      // If this container is visible
	      if ($this.inView()) {

		        // Match target heights
		        matchHeight($target);
		    }

		}, settings.buffer))

	};

}( $ ));

// ==========================================//
// Stagger In
// ==========================================//

function staggerIn($parent, $target) {
	let staggerIn = new TimelineMax();

	staggerIn.staggerFromTo($target, 0.8, { autoAlpha: 0, y: 20, ease: Power2.easeOut }, {autoAlpha: 1, y: 0}, 0.2, 0.2);

	let staggerInScene = new ScrollMagic.Scene({ triggerElement: $parent[0], triggerHook: 1, reverse: false });

	staggerInScene
		.setTween(staggerIn)
		.addTo(controller);

}

$('.js-stagger-in').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('.js-stagger-in-watch:not(.slick-cloned)'));
})

$('.js-stagger-in-all').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('>*:not(.slick-cloned)'));
})

// ==========================================//
// Draw SVG
// ==========================================//

function msieversion() {

	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE");
	var edge = ua.indexOf("Edge");

	// console.log(msie);

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || edge > -1)  // If Internet Explorer, return version number
    {
        // alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        return true;
    }
    else  // If another browser, return 0
    {
        // alert('otherbrowser');
        return false;
    }

}

if (!msieversion()) {
	$('.js-draw-svg').each(function(){
		$(this).drawSVG();
	})

	// $('.js-hover-drawSVG').each(function(){
	// 	$(this).hoverDrawSVG();
	// })
}

// ==========================================//
// Parallax
// ==========================================//

$('.js-parallax').each(function(){
	$(this).parallax({scale: 0.1, duration: 0.2});	
})

// ==========================================//
// Equalizer
// ==========================================//

$('[data-equalize]').each(function(){
	$(this).equalize();
})

// ==========================================//
// Last In Row
// ==========================================//

function lastInRow($element) {
	var $siblings = $element.parent().find('>*');
	let offsetTop = $element.offset().top;
	var $item = $element;

	$siblings.slice($item.index()).each(function () {
        if ($(this).offset().top > offsetTop) {
            return false;
        } else {
            $item = $(this);
        }
    });

    return $item;
}

// ==========================================//
// Lazy Load Images
// ==========================================//

$('.js-lazy').each(function(){
	$(this).lazyLoad();
})

// ==========================================//
// Sticky Nav
// ==========================================//

var $nav = $('.js-nav');

function fillNav($nav) {
	if ($(window).scrollTop() != 0) {
		$nav.addClass('fill');
	}else {
		$nav.removeClass('fill');
	}
}

fillNav($nav);

$(window).on('scroll', _.throttle(function(){
	fillNav($nav)
}, 250));

// ==========================================//
// Filters
// ==========================================//

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('?'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

function currentFilter(){
	var filter = getUrlParameter('filters');
	console.log(filter);

	if (filter != undefined && filter != '') {
		console.log($(`#filter-${filter}`));

		$('.js-filters-link').removeClass('js-active');

		ajaxFilter($(`#filter-${filter}`).data('api-url'), $('.js-ajax-target'))

		$(`#filter-${filter}`).addClass('js-active');
	}
}

currentFilter();


var getAjaxParameter = function getUrlParameter(ajaxURL, param) {
	console.log(ajaxURL);
    var sURLVariables = ajaxURL.split('?');
    var sParameterName;
    var i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === param) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

function ajaxFilter(ajaxURL, $target) {
	$.ajax({
	  	dataType: "json",
	  	url: currentURL + ajaxURL,
	  	success: function(response){
	  		var items = response.data.items;
	  		var currentURL = window.location.pathname;
	  		var newURL;

	  		if (getAjaxParameter(ajaxURL, 'filters') != undefined) {
	  			newURL = currentURL + '?filters=' + getAjaxParameter(ajaxURL, 'filters');
	  			window.history.replaceState({}, currentURL, newURL);
	  		}else {
	  			window.history.replaceState({}, currentURL, currentURL);
	  		}


	  		$target.html('');

	  		for (var i = 0; i < items.length; i++) {
	  			$target.append($(items[i].html));
	  		}

	  		$('[data-equalize]').each(function(){
				$(this).equalize();
			})
	  	}
	});
}

function ajaxResponse(ajaxURL) {
	$.ajax({
	  	dataType: "html",
	  	url: currentURL + ajaxURL,
	  	success: function(response){
	  		// var $template = $(response);

	  		return response;
	  	}
	});
}

$('.js-filters').each(function(){
	var $parent = $(this);
	var $links = $parent.find('.js-filters-link');
	var $ajaxTarget = $parent.find('.js-ajax-target');


	$links.on('click', function(e){
		e.preventDefault();
		var $this = $(this);
		var ajaxURL = $this.data('api-url');
		
		ajaxFilter(ajaxURL, $ajaxTarget);

		$parent.find('.js-active').removeClass('js-active');
		$this.addClass('js-active');

	});
})

// ==========================================//
// Accordion Grid
// ==========================================//

$('.js-accordion-grid').each(function(){
	var $grid = $(this);

	$('body').on('click', '.js-accordion-grid-item', function(e){
		e.preventDefault();

		var $this = $(this);
		var $target = lastInRow($this);
		var ajaxURL = $this.data('api-url');

		if (!$this.hasClass('js-active')) {
			var $template;

			$.ajax({
			  	dataType: "html",
			  	url: currentURL + ajaxURL,
			  	success: function(response){
			  		var $template = $(response);
					
					$grid.find('.js-active').removeClass('js-active');
					$this.addClass('js-active');

					$grid.find('.js-accordion-grid-content').slideUp(function(){
						$(this).remove();
					});

					$target.after($template);

					$grid.find('.js-accordion-grid-content').slideDown();
			  	}
			});

		} else {
			$grid.find('.js-accordion-grid-content').slideUp(function(){
				$this.blur();
				$grid.find('.js-active').removeClass('js-active');
				$(this).remove();
			});
		}


	});

	$('body').on('click', '.js-accordion-grid-content .svg-close', function(){
		$grid.find('.js-accordion-grid-content').slideUp(function(){
			$grid.find('.js-active').removeClass('js-active');
			$(this).remove();
		});
	})
})

hamburger('#hamburger', '.js-nav', '.js-mobile-nav');

function hamburger(hamburger, nav, mobileNav) {
	var $nav = $(nav);
	var $mobileNav = $(mobileNav);
	var $hamburger = $(hamburger);
	var $lineOne = $hamburger.find('span:first-child');
	var $lineTwo = $hamburger.find('span:nth-child(2)');
	var $lineThree = $hamburger.find('span:last-child');
	var hamburgerAnimation = new TimelineMax({paused: true});
	var navAnimation = new TimelineMax({paused: true});
	var canClick = 1;

	hamburgerAnimation
	.to([$lineOne, $lineThree], 0.3, {top: "50%"}, 'start')
	.set($lineTwo, {autoAlpha: 1}, 'start')
	.set($lineTwo, {autoAlpha: 0}, 'mid')
	.to($lineOne, 0.3, {rotation: 45}, 'end')
	.to($lineThree, 0.3, {rotation: -45}, 'end');

	navAnimation
    .fromTo($mobileNav, 0, {display: "none"}, {display: "block"}, 'start')
    .from($mobileNav, 0.25, {autoAlpha: 0}, 'end')
    

    $hamburger.on('click', function(){
    	if (canClick) {
    		canClick = 0;
    		if (!$hamburger.hasClass('open')) {
    			// scrollPos = $(window).scrollTop();
    			hamburgerAnimation.restart();
    			navAnimation.restart();
    			$hamburger.addClass('open');
    			$nav.addClass('open');
    		}else {
    			hamburgerAnimation.reverse();
    			navAnimation.reverse();
    			$hamburger.removeClass('open');
    			$nav.removeClass('open');
    		}
    		setTimeout(function(){
    			canClick = 1;
    		}, 500);
    	}
    })
} 

// ==========================================//
// Forms
// ==========================================//

autosize($('textarea'));

 $('#ContactForm_ContactForm, .userform').each(function(){
    let $this = $(this);
    let $email = $this.find('input[type="email"]');
    let $submit = $this.find('[type="submit"]');
    let $recaptcha = $this.find('.g-recaptcha');

    let $input = $this.find('input[required]:not([type="email"])');

        // needsValidation = 1;
    $submit.on('click', function(e){
        $input.each(function(){
            let $thisInput = $(this);
            let name = ($thisInput.attr('placeholder')) ? $thisInput.attr('placeholder') : insertSpaces($thisInput.attr('name'));
            // let name = $thisInput.attr('name');

            // name.replace(/([A-Z])/g, ' $1').trim()

            if ($(this).val() == '') {
                // console.log('no value');
                e.preventDefault();
                if ($thisInput.prev().hasClass('input-err')) {
                    $thisInput.prev().remove();
                }
                $thisInput.before(`<p class="input-err">${name} is not valid</p>`);
            }else {
                if ($thisInput.prev().hasClass('input-err')) {
                    $thisInput.prev().remove();
                }
            }
        })
        if ($email.length >= 1) {
            $email.each(function(){
                let $thisInput = $(this);
                let emailAddress = $thisInput.val();
                if (!isValidEmailAddress(emailAddress)) {
                    e.preventDefault();
                    $this.find('.email-err').remove();
                    $thisInput.before('<p class="email-err">Email is not valid</p>')
                    // console.log('not valid');
                }
            })
        }

        if ($recaptcha.length >= 1) {
            $recaptcha.each(function(){
                let $thisInput = $(this);
                let recaptcha = grecaptcha.getResponse();
                if (recaptcha == '') {
                    e.preventDefault();
                    $this.find('.recaptcha-err').remove();
                    $thisInput.before('<p class="input-err recaptcha-err">Please complete the captcha</p>')
                    // console.log('not valid');
                }
            })
        }
    })

    $this.on('submit', function(e){
    	e.preventDefault();
		$.ajax({
			type: 'POST',
			url : $this.attr('action'),
			data: $this.serialize()
		}).done(function (response) {
      try {
  			let data = JSON.parse(response);
			  $this.find('.message').show().text(data.message);
      } catch {
        $this.find('.message').show().text('Thank you, we have recieved your message.');
      }

      // jquery animate scroll to top of form
      $('html, body').animate({
        scrollTop: $this.offset().top - 200
      }, 500);


			// alertify.alert(data.message);
			$this[0].reset();
    }).fail(function (response) {
      try {
				let data = JSON.parse(response);
				$this.find('.message').show().text(data.message);
      } catch {
        $this.find('.message').show().text('Sorry, there has been an error. Please try again.');
      }

      // jquery animate scroll to top of form
      $('html, body').animate({
        scrollTop: $this.offset().top - 200
      }, 500);

				// alertify.alert(data.message);
			}
		);
		return false;
    })
})

// if ($contactForm.length) {
// 	$contactForm.parsley().on('form:submit', () => {
// 	});
// }

function insertSpaces(string) {
	string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string;
}

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}

function scrollTo($target, offset){
    let scrollTo = $target.offset().top - offset;

    TweenLite.to(window, 1.5, {scrollTo: {y:scrollTo, autoKill:false}, ease:Power1.easeInOut});
}

$('[data-scroll-to]').on('click', function(e){

	let $this = $(this);
	let $target = $($this.data('scroll-to'));

	e.preventDefault();
	scrollTo($target, $('.js-nav').height());

});

console.log(window.location.hash.substr(1));

import { each, closest } from 'meteora';

class PlaceholderLabels {
  constructor(form, options = {}) {
    this.form = form;
    this.inputTypes = options.inputTypes || ['text', 'email', 'number', 'file', 'textarea, password'];
    this.typeOfInput = null;

    this.inputTypes.forEach((type) => {
      this.typeOfInput = (type == 'textarea') ? this.form.querySelectorAll(type) : this.form.querySelectorAll(`input[type="${type}"]`);

      for (let i = 0; i < this.typeOfInput.length; i++) {
        let label = closest(this.typeOfInput[i], 'label');
        if (label !== null) {
          label.style.display = 'none';
          if (this.typeOfInput[i].getAttribute('placeholder') == undefined) this.typeOfInput[i].setAttribute('placeholder', label.innerText);
        }
      }
    });
  }
}

each('form', (form) => {
  new PlaceholderLabels(form);
});